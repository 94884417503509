import * as React from "react";
import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import ReactDOMServer from 'react-dom/server';
import LinkTranslated from "../../LinkTranslated";

export const ContactForm = () => {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const intl = useIntl();
  const placeholder1 = intl.formatMessage({id: 'form-1-placeholder'});
  const placeholder2 = intl.formatMessage({id: 'form-2-placeholder'});
  const placeholder3 = intl.formatMessage({id: 'form-3-placeholder'});
  const placeholder4 = intl.formatMessage({id: 'form-4-placeholder'});

  

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://577pq12jh4.execute-api.ap-southeast-2.amazonaws.com/Production/contact-mega", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          desc: message
        }),
      });
      console.log('res',res.body);
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        console.log('posted')
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
  <>
    <form id="contact-form" method="post">
      <h4><FormattedMessage id="form-1-label" /></h4>
      <input
        type="text"
        id="name-input"
        placeholder={placeholder1}
        className="form-control"
        style={{ width: "100%", height:"35px" }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <h4><FormattedMessage id="form-2-label" /></h4>
      <input
        type="phone"
        id="phone-input"
        placeholder={placeholder2}
        className="form-control"
        style={{ width: "100%", height:"35px" }}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <br />
      <h4><FormattedMessage id="form-3-label" /></h4>
      <input
        type="email"
        id="email-input"
        placeholder={placeholder3}
        className="form-control"
        style={{ width: "100%", height:"35px" }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <h4><FormattedMessage id="form-4-label" /></h4>
      <textarea
        id="description-input"
        rows="3"
        placeholder={placeholder4}
        className="form-control"
        style={{ width: "100%" }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <br />
      {/* <div
        class="g-recaptcha"
        data-sitekey="6Lc7cVMUAAAAAM1yxf64wrmO8gvi8A1oQ_ead1ys"
        className="form-control"
        styles={{ width: "100%" }}
      ></div> */}
      <button
        type="button"
        onClick={handleSubmit}
        className="btn btn-lg"
        style={{ marginTop: "20px", backgroundColor:'#4ab9cf', color:'white' }}
      >
        <FormattedMessage id="form-submit" />
      </button>
    </form>
  </>
  )
}
